<template>
    <div>
        <block v-if="loading"></block>
        <div>
            <v-row cols="12">
                <v-radio-group v-model="search.typeData"  class="ma-3">
                    <v-radio label="Ver reporte con datos de prueba" value="00"></v-radio>
                    <v-radio label="Ver reporte con datos de producción" value="01"></v-radio>
                </v-radio-group>
            </v-row>

            <v-row class="mx-2 pt-5 d-flex justify-right">
                <date-picker label="Fecha de solicitud" @dateSelected="(param) => (dateFilter = param)" class="mx-5" />     
                <div>
                    <v-btn class="btn-add my-1 mx-2" @click="getReportByDate">Filtrar<v-icon>mdi-filter</v-icon></v-btn>
                </div>
            </v-row>

            <DxDataGrid  
            :dataSource="SummaryData"
            :showBorders="true"
            ref="dataGrid"
            @exporting="onExporting"
            :scrolling="{ useNative: true }"
            :paging="{ enabled: false }"
            :column-auto-width="true" 
            style="max-height: 600px;"
            >
                <DxExport :enabled="true" />
                <DxScrolling mode="virtual"/>
                <DxLoadPanel :enabled="true"/>

                <DxColumn dataField="LegalName" caption="NOMBRE CLIENTE"></DxColumn>
                <DxColumn dataField="SellerName" caption="VENDEDOR"></DxColumn>
                <DxColumn dataField="ZoneClient" caption="ZONA"></DxColumn>
                <DxColumn :width="150" dataField="InvoiceType" caption="TIPO DOCUMENTO"></DxColumn>
                <DxColumn :width="350" dataField="ID_Invoice" caption="NÚMERO INTERNO"></DxColumn>
                <DxColumn :width="350" dataField="CodigoGeneracion" caption="CÓDIGO DE GENERACIÓN"></DxColumn>
                <DxColumn :width="300" dataField="NumeroControl" caption="NÚMERO DE CONTROL"></DxColumn>
                <DxColumn :width="150" dataField="InvoiceDateToShow" caption="FECHA"></DxColumn>
                <DxColumn :width="150" dataField="ExpirationDateToShow" caption="VENCE"></DxColumn>
                <DxColumn :width="100" dataField="Days" caption="DIAS"></DxColumn>
                <DxColumn dataField="ChargesFormatted" caption="MONTO"></DxColumn>
                <DxColumn dataField="PaymentsFormatted" caption="ABONO"></DxColumn>
                <DxColumn dataField="BalanceFormatted" caption="SALDO"></DxColumn>
                <DxColumn dataField="Balance_0_to_45_DaysFormatted" caption="0 - 45 DÍAS"></DxColumn>
                <DxColumn dataField="Balance_46_to_70_DaysFormatted" caption="46 - 70 DÍAS"></DxColumn>
                <DxColumn dataField="Balance_71_to_90_DaysFormatted" caption="70 - 90 DÍAS"></DxColumn>
                <DxColumn dataField="Balance_91_to_150_DaysFormatted" caption="91 - 150 DÍAS"></DxColumn>
                <DxColumn dataField="Over151Formatted" caption="MÁS DE 150 DÍAS"></DxColumn>
            </DxDataGrid>
        </div>
    </div>
</template>

<script>
import { printToExcel } from "@/helpers/printToexcel";
import { DxDataGrid, DxColumn, DxExport, DxScrolling, DxLoadPanel } from 'devextreme-vue/data-grid'
import DatePicker from '@/components/DatePicker'
import Block from "@/components/Block";

export default {
    name: "SummaryAccountsReceivable",
    components: {
        DatePicker,
        Block,
        DxDataGrid,
        DxColumn,
        DxExport,
        DxScrolling,
        DxLoadPanel
    },
    props: ['win'],
    data() {
        return {
            loading: false,
            search: {
                typeData: "01",
            },
            SummaryData: [],
            dateFilter: null
        }
    },
    mounted() {
        this.getReportByDate()        
    },
    methods: {
        onExporting(e) {
            printToExcel(e, "Resumen de Cuentas por Cobrar");
        },
        async getReportByDate() {
            try {
                this.loading = true
    
                let rdate = this.formatDateString(this.dateFilter)
                
                const response = await this.$API.customers.getsAccountsReceivableSummaryV2({ requestdate: rdate, envtype: this.search.typeData });

                this.SummaryData = response;
            } catch (error) {
                alert(error)
            } finally {
                this.loading = false
            }
        },
        formatDateString(dateString) {
            if (!dateString) {
                const today = new Date();
                const year = today.getFullYear();
                const month = String(today.getMonth() + 1).padStart(2, '0');
                const day = String(today.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            }

            const [day, month, year] = dateString.split('/');
            return `${year}-${month}-${day}`;
        }
    }
}
</script>